import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location, DatePipe , TitleCasePipe } from '@angular/common';
import { Application } from 'src/app/enum/application.enum';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor( private readonly router: Router,
    private readonly location: Location,) { }
  refreshInviteMember = new BehaviorSubject(null);
  refreshStoreList= new BehaviorSubject(null);
  refreshperfumeList= new BehaviorSubject(null);
  refreshIngredientList= new BehaviorSubject(null);
  refreshfeedbackList= new BehaviorSubject(null);
  refreshUserMangementList = new BehaviorSubject(null);
  refreshInviteList= new BehaviorSubject(null);
  clearLocalStorage() {
    localStorage.clear();
  }
    /**
   * @description set data to local storage
   * @param data object to store
   */
     setLocalStorage(data: object) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
            this.setLocalStorageKeyValues(key, data[key]);
        }
      }
    }
  
    setLocalStorageKeyValues(key, value) {
      localStorage.setItem(key, value);
    }
    getLocalStorageItem(key) {
        return localStorage.getItem(key);
    }
  
    setUrlParams(params) {
      let urlParams = '';
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          urlParams += `${urlParams === '' ? '?' : '&'}${key}=${params[key]}`;
        }
  
      }
      return urlParams;
    }

  goBack() {
    this.location.back();
  }
}
