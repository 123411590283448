
<button id="nota-widget-btn" class="open-button" *ngIf ='!showConv'(click) ="toggleConversationalView()" style="border-radius: 50%;"> 
    <img src="./assets/perfume icon .jpeg" alt="Girl in a jacket" style="height: 43px;"></button>
<div class="chat-popup" id="myForm"  *ngIf ='showConv' >
    <a type="button" class="btn cancel" (click)="toggleConversationalView()"><img src="./assets/cross.jpeg" alt="Girl in a jacket"  style="    height: 17px;
        float: right;"></a>
  <form action="/action_page.php" class="form-container"  [ngStyle]="{ 'backgroundColor':   'blue'}">
    <img src="./assets/perfume icon .jpeg" alt="Girl in a jacket"  style="height: 210px">
    <button type="submit" class="btn">Send</button>
  </form>
</div> 