import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [  
  { path: '', redirectTo: 'landing-page', pathMatch: 'full' },
  //{ path: '**', redirectTo: 'landing-page', pathMatch: 'full' },
  { path: 'landing-page', loadChildren: () => import('./module/landing-page/landing-page.module').then(m => m.LandingPageModule) },
  { path: 'admin', loadChildren: () => import('./module/admin/admin.module').then(m => m.AdminModule) },
  { path: 'dashboard', loadChildren: () => import('./module/authenticate/authenticate.module').then(m => m.AuthenticateModule) },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
