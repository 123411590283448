import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './core/error.interceptor';
import { HttpService } from './core/http.service';
import { WidgetComponent } from './module/widget/widget.component';
 import { WidgetModule } from './module/widget/widget.module';
import { BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { ModalModule} from 'ngx-bootstrap/modal';
import { LoaderComponent } from './shared/loader/loader.component'
  @NgModule({ declarations: [
        AppComponent,
        LoaderComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(environment.toastrConfig),
        BsDropdownModule,
        ModalModule.forRoot()], providers: [HttpService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { 
  constructor(private injector: Injector) { }   
ngDoBootstrap() {    
const el = createCustomElement(WidgetComponent, { 
injector: this.injector 
});    
customElements.define('app-widget', el);  }
}
