export const VALIDATION_MESSAGE = {
  REQUIRED: 'This field is required.',
  EMAIL_REQUIRED: 'Email address is required',
  WEBSITE_REQUIRED: 'Website domain is required',
  PASSWORD_REQUIRED: ' Password is required',
  CURRENT_PASSWORD_REQUIRED: 'Current Password is required',
  NEW_PASSWORD_REQUIRED: 'New Password is required',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm Password is required',
  INVALID_EMAIL: 'Email is invalid.',
  INVALID_NAME: 'Name is invalid.',
  INVALID_PASSWORD: 'Password is invalid.',
  INVALID_PASSWORD_FORMAT:
    'The password should have atleast an uppercase, a number, a special character and contains 8 characters.',
  CONFIRM_PASSWORD_ERROR: "The confirm password you've entered does not match.",
  INVALID_FIRST_NAME: 'First name is invalid.',
  INVALID_LAST_NAME: 'Last name is invalid.',
  INVALID_BUSINESS_NAME: 'Organization name is invalid.',
  INVALID_INTEGRATION_NAME: 'Integration name is invalid.',
  INVALID_FILE_SIZE: 'File is too large. Only 5MB is allowed.',
  INVALID_FILE_FORMAT: 'Invalid file type.',
  STATE_CITY_REQUIRED: 'State and city is required',
  PROFILE_SUCESS: 'Profile data saved.',
  OTP_REQUIRED: 'OTP Required',
  PHONE_REQUIRED: 'Phone Number is invalid',
  INVALID_CAMPAIGN: 'Campaign name is invalid.',
  RESET_PASSWORD:
    'Password should contain uppercase, spacial character and numeric value with max length of 8',
  API_401_ERROR: 'Please login again',
  API_500_ERROR: 'Something went wrong please try again',
  API_502_ERROR: 'Server is down, please try after some time',
  STORE_DELETED: ' Store deleted successfully',
  CONFIRM_PASSWORD: 'Confirm Password did not match',
  CHANGE_PASSWORD: 'Reset password successfully',
  SEND_INVITATION: 'Send invitation successfully',
  DELETE_PERFUME: 'Perfume deleted successfully',
  EDIT_SUCCESSFULLY: 'Perfume updated successfully',
  EDIT_SUCCESSFULLY_INGREDIENTS: 'Ingredient updated successfully',
  ADD_PERFUME: 'Perfume added successfully',
  ADD_INGREDIENTS: 'Ingredient added successfully',
  DELETE_REVIEW: ' Review deleted successfully',
  STORE_ADD: 'Store Add successfully',
  DELETE_INVITE: '  Invite deleted successfully',
  DELETE_INGEDIENT: '  Ingredient deleted successfully',
  STORE_EDIT: 'Store updated successfully',
};

export const DEFAULT_PARAMS = {
  page_num: 1,
  limit: 1000,
  page_size: 10,
};

export const MONTH_NAME = [
  { value: 1, name: 'January' },
  { value: 2, name: 'February' },
  { value: 3, name: 'March' },
  { value: 4, name: 'April' },
  { value: 5, name: 'May' },
  { value: 6, name: 'June' },
  { value: 7, name: 'July' },
  { value: 8, name: 'August' },
  { value: 9, name: 'September' },
  { value: 10, name: 'October' },
  { value: 11, name: 'November' },
  { value: 12, name: 'December' },
];

export const LANGUAGE = {
  ENGLISH: 'ENGLISH',
  ARABIC: 'ARABIC',
};

export const REPORTTYPE = [
  { value: 'monthly', name: 'monthly' },
  { value: 'quarterly', name: 'quarterly' },
  { value: 'bi-annually', name: 'bi-annually' },
];

export const TYPE = [
  { value: 'note', name: 'note' },
  { value: 'sub accord', name: 'sub accord' },
  { value: 'main accord', name: 'main accord' },
];



// export const MainNotes = [
//   'floral',
//   'powdery',
//   'Musky',
//   'fruity',
//   'Tropical',
//   'Sweet',
//   'Creamy',
//   'gourmand',
//   'citrus',
//   'Aromatic',
//   'green',
//   'Chypre',
//   'spicy',
//   'risens',
//   'Incense',
//   'Animalic',
//   'leather',
//   'woody',
//   'tobacco',
//   'smokey',
//   'Earthy',
//   'Amber',
//   'Aqua / Ozonic',
//   'metallic',
//   'Boozy',
// ];

// export const SubNotes = [
//   'floral',
//   'powdery',
//   'Musky',
//   'fruity',
//   'Sweet',
//   'Creamy',
//   'gourmand',
//   'citrus',
//   'Aromatic',
//   'green',
//   'Chypre',
//   'spicy',
//   'resien',
//   'incense',
//   'Animalic',
//   'leather',
//   'woody',
//   'tobacco',
//   'smokey',
//   'Earthy / Patchouli',
//   'Amber',
//   'aqua',
//   'Metallic',
//   'Boozy',
//   'Dusty',
//   'fatty',
// ];

// export const PerfumeNotes = [
//   'Rose',
//   'Rose accord (a)',
//   'Rose water',
//   'Jasmine',
//   'Jasmine sambac',
//   'Jasmin anamalic',
//   'Muguet',
//   'Lily of the valley',
//   'Ylang-ylang',
//   'Cherry blossom',
//   'Camomille',
//   'Lotus',
//   'Lavender (spice/hot)',
//   'Lavender (fresh)',
//   'Tuberose',
//   'Geranium',
//   'Tansy',
//   'Heliotrope',
//   'Camellia',
//   'Gardenia',
//   'Tulip',
//   'Carnation',
//   'Lily',
//   'Osmanthus',
//   'Orchid',
//   'Magnolia',
//   'Peony',
//   'Freesia',
//   'Mimosa',
//   'Lilac',
//   'Frangipani',
//   'Violet',
//   'Orris',
//   'Iris',
//   'Powdery',
//   'Men powder (after shave)',
//   'Cosmetics powdery',
//   'Baby powdery',
//   'Aldehyde',
//   'Aldehyde citrus',
//   'Aldehyde peach',
//   'Clean note',
//   'Soapy note',
//   'Zesty',
//   'Musk',
//   'Deer musk',
//   'Cleen musk (amberette)',
//   'Apricot',
//   'Peach',
//   'Pear',
//   'Raspberry',
//   'Cherry',
//   'Blackberry',
//   'Guava',
//   'Melon',
//   'Green apple',
//   'Blueberry',
//   'Watermelon',
//   'Tropical fruit',
//   'Passion fruit',
//   'Mango',
//   'Date',
//   'Dry fruit',
//   'Strawberry',
//   'Lychee',
//   'Fig',
//   'Pineapple',
//   'Grape',
//   'Dewberry',
//   'Fruity note',
//   'Plum',
//   'Quince',
//   'Apple',
//   'Apple juice',
//   'Pomegranate',
//   'Honey wax',
//   'Honey',
//   'Sweet',
//   'Sugar',
//   'Bubble gum',
//   'Cotton candy',
//   'Toffee',
//   'Caramel',
//   'Burned sugar',
//   'Coconut',
//   'Vanilla',
//   'Vanilla absolute',
//   'Bakery',
//   'Nut',
//   'Almond',
//   'White chocolate',
//   'Cacao',
//   'Chocolate',
//   'Dark chocolate',
//   'Milky note',
//   'Lactonic',
//   'Creamy note',
//   'Citrus opening',
//   'Citrus note',
//   'Mandarin',
//   'Bitter orange',
//   'Sweet orange',
//   'Lime',
//   'Orange',
//   'Pomelo',
//   'Lemon',
//   'Lemongrass',
//   'Neroli',
//   'Petitgrain',
//   'Grapefruit',
//   'Bergamot',
//   'Lavandin',
//   'Sage',
//   'Green notes',
//   'Rosemary',
//   'Thyme',
//   'Cucumber',
//   'Fennel',
//   'Tea tree',
//   'Camphor',
//   'Basil',
//   'Citronella',
//   'Cassia leaf',
//   'Bamboo',
//   'Green fruit',
//   'Cypress',
//   'Rice',
//   'Green leaf',
//   'Violet leaf',
//   'Tomato leaf',
//   'Fig leaf',
//   'Mint',
//   'Oakmoss',
//   'Artemisia',
//   'Mate',
//   'Vetiver',
//   'Eucalyptus',
//   'Pine',
//   'Juniper',
//   'Melissa',
//   'Chypre',
//   'Fern',
//   'Moss',
//   'Spicy note',
//   'Tonka',
//   'Anise',
//   'Coriander',
//   'Caraway',
//   'Bay leaf',
//   'Turmeric',
//   'Cassis',
//   'Cumin',
//   'Cinnamon',
//   'Cardamom',
//   'Black pepper',
//   'Pink pepper',
//   'Nutmeg',
//   'Red pepper',
//   'Clove',
//   'Chilli',
//   'Pay leaf',
//   'Ginger',
//   'Carrot seeds',
//   'Coffee bean',
//   'Coffee',
//   'Coffee latte',
//   'Arabic coffee',
//   'Saffron',
//   'Tobacco leave',
//   'Tobacco sigar',
//   'Tobacco',
//   'Tea',
//   'Green tea',
//   'Resin',
//   'Elemi',
//   'Frankincense',
//   'Labdanum',
//   'Oman',
//   'Balsamic',
//   'Mastic',
//   'Styrax',
//   'Myrrh',
//   'Benzoin',
//   'Incense',
//   'Fire smoke',
//   'Leather',
//   'Sharp leather',
//   'Deer leather',
//   'Civet',
//   'Animalic',
//   'Ambergris',
//   'Amber',
//   'Patchouli',
//   'Patchouli light',
//   'Oud',
//   'Agarwood',
//   'Woody note',
//   'Sandalwood',
//   'Ceder wood',
//   'Oak wood',
//   'Guaiac wood',
//   'Smoky wood',
//   'Nagarmotha',
//   'Green wood (teak)',
//   'Cambodian oud',
//   'Metallic',
//   'Ocean',
//   'Aqua',
//   'Dust note',
//   'Oil',
//   'Phenolic',
//   'Tar',
//   'Rubber',
//   'Wet soil',
//   'Earthy note',
//   'Boozy',
//   'Wine',
//   'Cashmeran',
//   'Hay',
//   'Salt note',
//   'Fatty',
// ];
  
