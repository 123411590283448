export const environment = {
  production:  true,
  baseApiURL: 'https://api.kindi.sa.com/api/',
  toastrConfig: {
    maxOpened: 1,
    onActivateTick: true,
    progressBar:  false,
    preventDuplicates: true,
    disableTimeOut:  false,
    timeOut: 3000, // 15 seconds
    extendedTimeOut: 100,
     
  },
};
