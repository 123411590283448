import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
 

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated

})
export class WidgetComponent implements OnInit {
  showConv: any;
  backgroundshow =' green'
  show : any
  constructor(private ref: ChangeDetectorRef,private httpClient: HttpClient) { }
  ngOnInit(): void {
  }
  toggleConversationalView() {    
    // Toggle the value    
    this.showConv = !this.showConv;     
    // Trigger the change detection    
    this.ref.detectChanges();  
    }
  
  
  
}
